import $store from './store'
import axios from "axios";

axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(function(config) {
    $store.commit('LOADING', true)
    return config
}, function(error) {
    return Promise.reject(error)
})
axios.interceptors.response.use(function(response) {
    $store.commit('LOADING', false)
    return response
}, function(error) {
    $store.commit('LOADING', false)
    return Promise.reject(error)
})

const API_URL = $store.state.config.api_url

export default {
    loginQr(qr) {
        return this.getCsrf()
            .then(() => {
                 return axios.post(API_URL + 'api/crew/login', { authorization_code: qr })
                    .then(response => {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data
                        return axios.get(API_URL + 'api/crew/logged-crew-member')
                    })
            })
    },
    logOut() {
        axios.defaults.headers.common['Authorization'] = undefined // TODO: Wykonywac to po requescie
        return axios.post(API_URL + 'api/crew/revoke-tokens')
    },
    getCsrf() {
        return axios.get(API_URL + 'sanctum/csrf-cookie')
    },
    getAppConfig() {
        return axios.get(API_URL + 'api/crew/app-config')
    },
    validateUserData(userData) {
        return axios.post(API_URL + 'api/crew/customers/validate-data', userData)
    },
    getReceiptRegisterAvailableShops() {
        return axios.get(API_URL + 'sa/shops')
    },
    getStaticContentPage(uuid) {
        return axios.get(API_URL + 'api/pages/' + uuid)
    },
    storeUser(userData) {
        return axios.post(API_URL + 'api/crew/customers', userData)
    },
    getCustomerById(id) {
        return axios.get(API_URL + 'api/crew/customers/' + id)
    },
    getCustomerByEmail(email) {
        return axios.get(API_URL + 'api/crew/customers/get-by-email/' + email)
    },
    getCustomerByPhoneNumber(phone_number) {
        return axios.get(API_URL + 'api/crew/customers/get-by-phone-number/' + phone_number)
    },
    getCustomerByExternalIdentifier(eid) {
        return axios.get(API_URL + 'api/crew/customers/get-by-external-identifier/' + eid)
    },
    updateCustomerAgreements(customer_uuid, agreements) {
        return axios.patch(API_URL + 'api/crew/customers/' + customer_uuid + '/update-agreements', {agreements: agreements})
    },
    validateEventParticipationRequirements(event_uuid, customer_uuid) {
        return axios.post(API_URL + 'api/crew/promotional-events/validate-participation-requirements', {
            'promotional_event_uuid': event_uuid,
            'customer_uuid': customer_uuid,
        })
    },
    storeEventParticipation(event_uuid, customer_uuid, data = {}) {
        return axios.post(API_URL + 'api/crew/promotional-events/participation', {
            'promotional_event_uuid': event_uuid,
            'customer_uuid': customer_uuid,
            ...data
        })
    },

    parseReceipt(receiptImageData, customer_uuid)
    {
        return axios.post(API_URL + 'api/crew/receipts/' + customer_uuid + '/parse', {
            image_data: receiptImageData
        })
    },
    validateReceipts(event_uuid, customer_uuid, receipts)
    {
        return axios.post(API_URL + 'api/crew/promotional-events/' + event_uuid + '/receipts/validate', {
            customer_uuid: customer_uuid,
            receipts: receipts
        })
    },
    storeReceipts(event_uuid, customer_uuid, receipts)
    {
        return axios.post(API_URL + 'api/crew/promotional-events/' + event_uuid + '/receipts', {
            customer_uuid: customer_uuid,
            receipts: receipts
        })
    },
    getReceipt(uuid)
    {
        return axios.get(API_URL + 'api/crew/receipts/' + uuid)
    },
    getReceipts(customer_uuid, status = null) {
        return axios.get(API_URL + 'api/crew/receipts/' + customer_uuid + '/' + status)
    },
    updateReceipt(receiptData) {
        receiptData.total_value = parseFloat(receiptData.total_value)
        return axios.patch(API_URL + 'api/crew/receipts/' + receiptData.uuid, receiptData)
    },
    deleteReceipt(uuid) {
        return axios.delete(API_URL + 'api/crew/receipts/' + uuid)
    },


    uploadReceiptImage(userId, receiptImageData) {
        return axios.post(API_URL + 'sa/add-receipt', {
            user_id: userId,
            image_data: receiptImageData
        })
    },


    getAvailableRewards(customer_uuid, promotional_event_uuid) {
        return axios.get(API_URL + 'api/crew/promotional-events/' + promotional_event_uuid + '/' + customer_uuid + '/available-rewards')
    },
    getRewardIssues(customer_uuid, promotional_event_uuid) {
        return axios.get(API_URL + 'api/crew/promotional-events/' + promotional_event_uuid + '/' + customer_uuid + '/reward-reservations')
    },
    reserveReward(reward_uuid, customer_uuid, promotional_event_uuid) {
        return axios.post(API_URL + 'api/crew/reward-issues/reserve', {
            reward_uuid: reward_uuid,
            customer_uuid: customer_uuid,
            promotional_event_uuid: promotional_event_uuid,
        })
    },
    collectReward(reward_issue_uuid, signature) {
        return axios.patch(API_URL + 'api/crew/reward-issues/' + reward_issue_uuid + '/collect', {
            signature: signature
        })
    },
}
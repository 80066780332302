<template>
  <div class="row">
    <div v-if="!disableAcceptAll" class="col-12 mb-3">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="check_all" v-model="allAccepted">
        <label class="form-check-label" for="check_all">Akceptuj wszystkie</label>
      </div>
      <hr/>
    </div>
    <div v-for="(agr, index) in availableAgreements" :key="'agr_'+index" class="col-12 mb-3">
      <div class="form-check">
        <input v-model="agreements[agr.uuid]" @change="handleChange"
               :class="{'is-invalid': errors['agreements.'+agr.uuid]}"
               class="form-check-input" type="checkbox" :id="'check_'+agr.uuid" :required="agr.is_required">
        <div class="label_container">
          <label class="form-check-label" :for="'check_'+agr.uuid">
            <div v-html="_n(agr.short_content) + (agr.is_required ? '<span class=\'text-danger\'> *</span>' : '')"></div>
          </label>
          <form-control-errors :error-bag="errors" :field="'agreements.'+agr.uuid"></form-control-errors>
        </div>
        <div v-if="agr.short_content !== agr.full_content" class="icon_more_container"
             data-bs-toggle="modal"
             :data-bs-target="'#agreement_modal_'+agr.uuid">
          <i class="icon-read"></i>
        </div>
      </div>

      <div :id="'agreement_modal_'+agr.uuid" class="modal" tabindex="-1">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="agr.full_content" v-html="__(agr.full_content)"></div>
                </div>
                <div class="col-12 text-center mt-5">
                  <button class="btn btn-primary" data-bs-dismiss="modal" type="button" @click="acceptAgreement(agr.uuid)">Zatwierdź zgodę</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import FormControlErrors from "@/components/FormControlErrors";
import { preprocessor } from '../mixins/utilities'

export default {
  name: "AgreementSet",
  components: {
    FormControlErrors
  },
  mixins: [preprocessor],
  props: {
    availableAgreements: {
      type: Array,
      required: true,
    },
    errors: {
      type: Array
    },
    disableAcceptAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allAccepted: false,
      agreements: {},
    }
  },
  watch: {
    allAccepted: function(newValue) {
      if(newValue) this.acceptAll()
      this.$nextTick(this.updateAllAccepted)
    },
  },
  computed: {
    acceptedAgreements() {
      return this.agreements
    }
  },
  created() {
    this.availableAgreements.forEach(a => {
      this.agreements[a.uuid] = false
    })
    this.$emit('update:agreements', this.acceptedAgreements)
  },
  methods: {
    acceptAll() {
      this.availableAgreements.map(agr => {
        this.acceptAgreement(agr.uuid)
      })
      this.$emit('update:agreements', this.acceptedAgreements)
    },
    acceptAgreement(uuid) {
      this.agreements[uuid] = true
    },
    handleChange() {
      this.$emit('update:agreements', this.acceptedAgreements)
      this.updateAllAccepted()
    },
    updateAllAccepted() {
      let hasFalse = false;
      this.availableAgreements.map(agr => {
        if(!this.agreements[agr.uuid]) hasFalse = true
      })
      this.allAccepted = !hasFalse;
    },
  }
}
</script>

<style scoped lang="scss">
.label_container {
  display: inline-block;
}
.icon_more_container {
  float: right;
  cursor: pointer;
}
</style>
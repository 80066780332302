<template>
  <div class="signature">
    <view-header title="Odbiór nagrody - podpis" @back="$router.push('/reward-issue-receipt-check/'+$route.params.uuid)"></view-header>

    <div class="container">
      <div class="row">
        <div v-if="!reward_issue" class="col-12 text-center">
          <vue-signature-pad ref="signature" :width="600" :height="300" class="signature_pad"></vue-signature-pad>
        </div>

        <div v-if="reward_issue" class="col-6 offset-3 text-center">
          <p>Protokół odbioru został zapisany prawidłowo. Nagroda może zostać wydana klientowi.</p>
          <reward-tile :reward="reward_issue.reward" :hide_description="true"></reward-tile>
        </div>
      </div>

      <div class="row my-5">
        <div class="col text-center">
          <button v-if="!reward_issue" class="btn btn-primary" @click="saveSignature">Zapisz</button>
          <router-link to="/customer-service" v-if="reward_issue" class="btn btn-primary">Zakończ</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import VueSignaturePad from 'vue3-signature-pad'
import api from '../../../api'
import RewardTile from "@/components/RewardTile";

export default {
  name: "Signature",
  components: {
    RewardTile,
    ViewHeader,
    VueSignaturePad
  },
  data() {
    return {
      reward_issue: null
    }
  },
  methods: {
    saveSignature() {
      let signature = this.$refs.signature.saveSignature()
      if(signature.is_empty) {
        console.error('ERROR_SIGNATURE_EMPTY')
      } else {
        api.collectReward(this.$route.params.uuid, signature.data)
          .then(response => { this.reward_issue = response.data; })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.signature_pad {
  margin: 0 auto;
  border: 1px solid $primary
}
</style>
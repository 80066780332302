<template>
  <div class="authorization_code_scan">
    <div class="container">
      <div class="row my-5">
        <div class="col text-center">
          <brand></brand>
        </div>
      </div>

      <div class="row">
        <div class="col text-center">
          <p>Aby zalogować się do aplikacji, zeskanuj kod QR umieszczony na Twoim identyfikatorze.</p>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <qr-stream @decode="login"></qr-stream>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <input :class="{'is-invalid': errors.authorizationCode}" type="hidden" class="form-control"/>
          <form-control-errors :error-bag="errors" field="authorizationCode"></form-control-errors>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import Brand from "@/components/Brand";
import { QrStream } from 'vue3-qr-reader'
import FormControlErrors from "@/components/FormControlErrors";

export default {
  name: "AuthorizationCodeScan",
  components: {
    Brand,
    QrStream,
    FormControlErrors
  },
  data() {
    return {
      errors: {}
    }
  },
  mounted() {
    this.login('authorization_code')
  },
  methods: {
    login(authorizationCode) {
      api.loginQr(authorizationCode)
        .then(response => {
          this.$store.dispatch('setLoggedIn', response.data)
          this.$router.push('/')
        })
        .catch(error => {
          if(error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="customer_service">
    <div class="container">
      <div class="row my-5">
        <div class="col text-center">
          <brand></brand>
        </div>
      </div>

      <div class="row row-cols-3 justify-content-center">
        <div v-if="$store.state.config.features.register_event_participation"  class="col my-3">
          <tile-button label="Rejestracja udziału w wydarzeniu" icon="event" @click="eventParticipation"></tile-button>
        </div>
        <div v-if="$store.state.config.features.reward_issue" class="col my-3">
          <tile-button label="Wydanie nagrody" icon="prize" @click="rewardSelect"></tile-button>
        </div>
        <div v-if="$store.state.config.features.receipt_register" class="col my-3">
          <tile-button label="Rejestracja paragonu" icon="add-receipt" @click="receiptList"></tile-button>
        </div>
        <div class="col my-3">
          <tile-button label="Informacje o użytkowniku" icon="user" @click="userInfo"></tile-button>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h6>Aktualnie obsługiwany użytkownik: <strong>{{ $store.getters.currentUserDisplayName }}</strong></h6>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <button class="btn btn-primary" @click="back">Powrót do ekranu głównego</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TileButton from "@/components/TileButton";
import Brand from "@/components/Brand";

export default {
  name: "CustomerService",
  components: {
    TileButton,
    Brand,
  },
  computed: {
    promotionalEvents() {
      return this.$store.state.config.promotionalEvents
    }
  },
  methods: {
    userInfo() {
      this.$router.push({ name: 'CurrentUserInfo', params: { refresh: true } })
    },
    receiptList() {
      this.$router.push('/receipt-list')
    },
    rewardSelect() {
      if(this.promotionalEvents.length > 1) {
        this.$router.push('/event-reward-select')
      } else {
        this.$router.push('/event-reward/' + this.promotionalEvents[0].uuid)
      }
    },
    eventParticipation() {
      if(this.promotionalEvents.length > 1) {
        this.$router.push('/event-participate-select')
      } else {
        this.$router.push('/event-participate/' + this.promotionalEvents[0].uuid)
      }
    },
    back() {
      this.$store.dispatch('clearCurrentUser')
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="receipt_list">
    <view-header title="Paragony" @back="back"></view-header>

    <div class="container">
      <div class="row mt-5">
        <div v-for="r in receipts" :key="'receipt_' + r.uuid" class="col-12 mb-4 pb-1 border-bottom border-dark receipt_container">
          <div class="row">
            <div class="col-3">
              <img :src="r.preview"/>
            </div>
            <div class="col">
              <div class="row">
                <div class="col-6">
                  <small class="text-uppercase">Wartość:</small><br/>
                  <h4>{{ (r.total_value / 100).toFixed(2) }} zł</h4>
                </div>
                <div class="col-6">
                  <small class="text-uppercase">Data dodania:</small><br/>
                  <h4>{{ r.created_at }}</h4>
                </div>
                <div class="col-6">
                  <small class="text-uppercase">Numer:</small><br/>
                  <h4>{{ r.receipt_number }}</h4>
                </div>
                <div class="col-6">
                  <small class="text-uppercase">Sklep:</small><br/>
                  <h4>{{ $store.getters.shopNameByUuid(r.shop_uuid) }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <router-link :to="'/receipt-correction/' + r.uuid" class="btn btn-dark btn-sm my-2" style="width: 100%">Edytuj</router-link>
            </div>
            <div class="col-4 offset-4">
              <button @click="deleteReceipt(r.uuid)" class="btn btn-danger btn-sm my-2" style="width: 100%">Usuń</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <router-link v-if="$store.state.config.receipt_scanner" to="receipt-scan" class="btn btn-light">Dodaj paragon</router-link>
          <router-link v-else to="receipt-correction" class="btn btn-light">Dodaj paragon</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import api from '../../../api'

export default {
  name: "ReceiptList",
  components: {
    ViewHeader,
  },
  data() {
    return {
      receipts: []
    }
  },
  mounted() {
    this.getReceipts()
  },
  methods: {
    back() {
      this.$router.push('/customer-service')
    },
    getReceipts() {
      api.getReceipts(this.$store.state.currentUser.uuid, 'processing')
          .then(response => {
            this.receipts = response.data
          })
    },
    deleteReceipt(uuid) {
      api.deleteReceipt(uuid)
        .then(() => {
          this.getReceipts()
        })
    }
  }
}
</script>

<style scoped lang="scss">
.receipt_container {
  img {
    max-width: 100%;
  }
  small {
    color: $gray-500;
    font-weight: 900;
    font-size: 12px;
  }
  h4 {
    font-size: 1rem;
  }
}
</style>
<template>
  <div class="register_new">
    <view-header title="Nowy klient" @back="$router.push('/register-new-user-data')"></view-header>

    <div class="container">
      <div class="row">
        <form class="col" @submit="next($event)">
          <div v-if="$store.state.config.customerRegisterConfig.register_agreements" class="row">
            <div class="col">
              <agreement-set v-model:agreements="userAgreements" :available-agreements="$store.state.config.customerRegisterConfig.register_agreements" :errors="formErrors"></agreement-set>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col text-center">
              <button class="btn btn-primary">Utwórz konto</button>
            </div>
          </div>
        </form>
      </div>

      <div class="row row-cols-3 mt-5 justify-content-center">
        <div v-for="(page, index) in $store.state.config.customerRegisterConfig.register_agreements_pages" :key="'static_page_'+index" class="col my-3">
          <tile-button :label="page.title" icon="document" @click="staticPage(page.uuid)"></tile-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import AgreementSet from "@/components/AgreementSet";
import api from '../../api'
import TileButton from "@/components/TileButton";

export default {
  name: "Agreements",
  components: {
    AgreementSet,
    ViewHeader,
    TileButton
  },
  data() {
    return {
      userAgreements: null,
      formErrors: {},
    }
  },
  computed: {
    registerData() {
      return {
        ...this.$store.state.register.userData,
        agreements: this.userAgreements,
      }
    }
  },
  methods: {
    next(e) {
      e.preventDefault()
      api.storeUser(this.registerData)
        .then(response => {
          this.$store.dispatch('setCurrentUser', response.data)
          this.$router.push('/customer-service')
        })
        .catch(error => {
          if(error.response.status === 422) {
            this.formErrors = error.response.data.errors
          }
        })
    },
    staticPage(uuid) {
      this.$router.push('/page/'+uuid)
    }
  }
}
</script>

<style scoped>

</style>